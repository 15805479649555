
import { computed, ref } from "vue";
import { Toast, List } from "vant";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { numberSearch } from "@/api/request.js";
import { valueToNode } from "@babel/types";
export default {
  components: {
    [List.name]: List,
  },
  props: {
    flag: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const router =useRouter()
    const flag1: any = ref(props.flag as any);
    const search:any = ref(computed(() => store.state.search));
    const page: any = ref(0);
    const size = ref(30);
    const searchList: any = ref([]);
    const list: any = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const getflag = () => {
      ctx.emit("getflag", false);
    };
    let numberend:any=[]
    const onSearch = async () => {
      page.value++;
      const data = await numberSearch(
        `number=${search.value}&page=${page.value}&size=${size.value}`
      );

      if (data.success == false) {
        Toast(data.message);
      } else {
        
        searchList.value= searchList.value.concat(data.data.list)
        console.log(search.value, "search", searchList.value);
        searchList.value.map((item:any)=>{
        let numberstart=  (item.number as any).slice(0,9)
      
        numberend.push((item.number as any).slice(10))
        numberend=Array.from(new Set(numberend)) 
        console.log(numberend);

        
        }) 
        numberend.map((item:any)=>{
          //
        })
        loading.value = false;
     
        // 数据全部加载完成
        if (data.data.list.length < 30) {
          finished.value = true;
        }
      }
    };
    const getorder = (item: any) => {
      console.log(item);
      const t = new Date();
      router.push({
        path: "/purchase/order",
        query: {
          t: t.getTime(),
          number: item.number,
          id: item.niceType,
          type: "2",
        },
      });
    };

    return { flag1, getflag, onSearch, searchList, loading, finished ,getorder};
  },
};
