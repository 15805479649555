
import { ref, nextTick, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Button, Icon, Search, Toast } from "vant";
import { Swipe, SwipeItem } from "vant";
import { ActionSheet } from "vant";
import search from "~/search.vue";
import {
  numberStarts,
  numberNicetype,
  openid,
  moreNumber,
  numberBatch,
} from "@/api/request.js";
import axios from "@/api/index.js";
import https from "@/utils/wxopenid.js";
export default {
  components: {
    [Button.name]: Button,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [ActionSheet.name]: ActionSheet,
    search: search,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const router = useRouter();
    const store = useStore();
    const value = ref("");
    const tjList = ref([]);
    const numberList = ref([]);
    const show = ref(false);
    const flag = ref(false);
    const search:any = ref();
    const reg = /^\d{1,4}$/g
    const searchList=ref({})
    const gettrial = () => {
      router.push({ path: "/purchase/trialNumber", query: { trial: 3 } });
    };

    // const openId: any = ref("");

    const getup = () => {
      router.push({ path: "/mine" });
    };

    // 搜索
    const onSearch =  () => {
      if(reg.test(search.value)){
         store.commit('getsearch',search.value)
      flag.value = true;
      search.value=''
      }else{
        console.log('dddd');
         Toast('请输入长度小于5的数')
      }
     
    };
      function getflag(data:any){
         flag.value=data
      }
    // 推荐靓号
    const getNumberStarts = async () => {
      const data = await numberStarts({ count: 6 }); //6：几条数据
      tjList.value = data.data;
      console.log(data, tjList.value);
    };
    getNumberStarts();
    // 靓号
    const getNumberNicetype = async () => {
      const data = await numberNicetype();
      numberList.value = data.data;
      console.log(data);
    };
    getNumberNicetype();
    const pageNum = ref(0);
    // 换一批
    const getNumberBatch = (niceTypeId: any, index: any) => {
      axios(`/number/numberbatch/${niceTypeId}`, "GET").then((res: any) => {
        (numberList.value[index] as any).numbers = res.data;
        console.log(res);
      });
    };
    // const getNumberBatch = async (niceTypeId: any, index: any) => {

    //   pageNum.value++;
    //   const data = await numberBatch({
    //     niceTypeId: niceTypeId, //靓号规则id
    //     pageNum: pageNum.value, //页数
    //     pageSize: 6, //每页数量
    //   });
    //   (numberList.value[index] as any).numbers = data.data;
    //   console.log(data);
    // };

    // 更多
    const getmoreNumber = async (niceTypeId: any, index: any) => {
      pageNum.value++;
      const data = await moreNumber({
        niceTypeId: niceTypeId, //靓号规则id
        pageNum: pageNum.value, //页数
        pageSize: 6, //每页数量
      });
      (numberList.value[index] as any).numbers = data.data;
      console.log(data);
    };
    const getReplace = (item: any, index: any) => {
      getNumberBatch(item.niceTypeId, index);
      //  getNumberNicetype();
    };

    const getorder = (item: any) => {
      console.log(item);
      const t = new Date();
      router.push({
        path: "/purchase/order",
        query: {
          t: t.getTime(),
          number: item.number,
          id: item.niceType,
          type: "2",
        },
      });
    };
    return {
      gettrial,
      getup,
      flag,
      show,
      tjList,
      numberList,
      search,
      searchList,
      onSearch,
      getflag,
      getorder,
      getReplace,
    };
  },
};
