import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23305010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "search" }
const _hoisted_2 = { class: "c_content" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "cr_number" }
const _hoisted_5 = { class: "cr_number1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_list = _resolveComponent("van-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.getflag && $setup.getflag(...args)))
    }, "返回"),
    _createVNode(_component_van_list, {
      loading: $setup.loading,
      "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => (($setup.loading) = $event)),
      finished: $setup.finished,
      "finished-text": "没有更多了",
      onLoad: $setup.onSearch
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.searchList, (value, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "c_radio",
              key: index,
              onClick: ($event: any) => ($setup.getorder(value))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(value.number), 1)
              ])
            ], 8, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"])
  ]))
}